<template>
  <div class="bg-white">
    <app-modal-confirm
      ref="plan_delete_modal"
      :modal-header-title="$t('common.delete confirm title')"
      @confirmed="deleteInformation"
    >
      <template v-slot:body>
        <p class="confirm-body">{{ $t("common.delete confirm") }}</p>
      </template>
    </app-modal-confirm>

    <app-modal-confirm
      ref="plan_tax"
      :modal-header-title="$t('announcements.confirm_title')"
      @cancel="handlerTax"
      @confirmed="closeModalTax"
    >
      <template v-slot:body>
        <p class="confirm-body">価格の{{rateTax}}%を超える金額が消費税額として入力されています。このまま設定してもよろしいですか？</p>
      </template>
    </app-modal-confirm>
    <validation-observer
      ref="entry"
      v-slot="{ passes, valid, validated }"
    >
      <form class="form-horizontal">
        <div class="card-body">
          <div class="form-group row" v-if="idEditing">
            <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label pl-3">{{ $t("common.id") }}</label>
            <div class="col-xl-5 col-lg-6 col-sm-6 p-t-8"> {{ entry.id }}</div>
          </div>
          <div v-if="meta.facility">
            <div class="form-group row">
              <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("plans.mansion") }}</label>
              <div class="col-xl-5 col-lg-6 col-sm-6 p-t-8">
                <p @click="handlerApartmentNameClick(meta.facility.apartment_id)" :data-original-title="meta.facility.apartment_name" style="color: #007bff;"
                   class="apartment_name app-table-p app-cell-tooltip mb-0">
                  {{meta.facility.apartment_name}}
                </p>
              </div>
            </div>
          </div>
          <div v-if="meta.facility">
            <div class="form-group row">
              <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("plans.facility") }}</label>
              <div class="col-xl-5 col-lg-6 col-sm-6 p-t-8">
                <p @click="handlerFacilityNameClick(meta.facility.id)" :data-original-title="meta.facility.name" style="color: #007bff;"
                   class="apartment_name app-table-p app-cell-tooltip mb-0">
                  {{meta.facility.name}}
                </p>
              </div>
            </div>
          </div>
          <validation-provider
            name="status_enabled"
            rules="required"
            v-slot="{ errors, invalid, validated }"
          >
            <div class="form-group row">
              <label class="col-xl-2 col-lg-3 col-sm-3 col-form-label">{{ $t("plans.status")}} <span
                class="app-require">＊</span>
              </label>
              <div class="col-xl-5 col-lg-6 col-sm-9 align-self-center">
                <app-radio v-for="(item, index) in PLAN_CONSTANT.status"
                           :isMarginLeft="index !=0 ? true : false" :key="item.id" :label="item.name"
                           :val="item.id" v-model="entry.status_enabled"/>
                <label v-if="invalid && validated" class="error">
                  {{ errors[0] }}
                </label>
              </div>
            </div>
          </validation-provider>
          <div class="form-group row">
            <label class="col-xl-2 col-lg-3 col-sm-2 col-form-label">{{ $t("plans.name") }}
              <span
                class="app-require">＊</span>
            </label>
            <div class="col-xl-5 col-lg-6 col-sm-7">
              <app-input
                name="name"
                rules="required"
                type="string128"
                :class="['flex-fill']"
                v-model="entry.name"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-2 col-lg-3 col-sm-2 col-form-label">{{ $t("plans.price_without_tax") }}
            </label>
            <div class="col-xl-5 col-lg-6 col-sm-7">
              <app-input
                name="plan_price"
                type="number-length:11"
                @focusOutEvent="focusOutEvent"
                :class="['flex-fill']"
                v-model="entry.price"
                :rules="VALIDATE.V_PLAN_PRICE"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-2 col-lg-3 col-sm-2 col-form-label">{{ $t("plans.tax") }}
            </label>
            <div class="col-xl-5 col-lg-6 col-sm-7">
              <app-input
                name="plan_tax"
                type="number-length:11"
                @focusOutEvent="focusOutEvent"
                :class="['flex-fill']"
                v-model="entry.tax"
                :rules="VALIDATE.V_PLAN_PRICE"
              />
            </div>
          </div>
          <validation-provider
            name="is_reduced_tax"
            v-slot="{ errors, invalid, validated }"
          >
          <div class="form-group row">
            <label class="col-xl-2 col-lg-3 col-sm-2 col-form-label">{{ $t("plans.is_reduced_tax") }}
            </label>
            <div class="col-xl-5 col-lg-6 col-sm-7 gr-icheck">
              <app-check-box
                :label="$t('plans.text_is_reduced_tax')"
                v-model="entry.is_reduced_tax"
                class="m-r-20"
                val="1"
              />
              <label v-if="invalid && validated" class="error">
                {{ errors[0] }}
              </label>
            </div>
          </div>
          </validation-provider>
          <div class="form-group row">
            <label class="col-xl-2 col-lg-3 col-sm-2 col-form-label">{{ $t("plans.web_url") }}</label>
            <div class="col-xl-5 col-lg-6 col-sm-7">
              <app-input
                name="web_url"
                :class="['flex-fill']"
                v-model="entry.url"
                type="string512"
                rules="url"
                disabled="true"
                id="plan_url"
              />
            </div>
            <div class="col-xl-1 col-lg-1 col-sm-1" v-if="idEditing">
             <img src="/images/copy-icon.png"  @click="copy">
            </div>
          </div>
          <div class="form-group row" >
            <label class="col-xl-2 col-lg-3 col-sm-3 col-form-label">{{ $t("plans.book_master") }} <span
              class="app-require">＊</span>
            </label>
            <div class="col-xl-5 col-lg-6 col-sm-7">
              <app-select
                name="book_master_id"
                rules="required"
                :options-data="meta.book_masters"
                v-model="entry.book_master_id"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-2 col-lg-3 col-sm-2 col-form-label">{{ $t("plans.plan_unit_minutes") }}
              <span
                class="app-require">＊</span>
            </label>
            <div class="col-xl-4 col-lg-5 col-sm-6 select_box mr-2 mr-lg-3">
              <app-select
                v-if="loading"
                name="plan_unit_minutes"
                rules="required"
                :options-data="meta.plan_unit_minutes"
                v-model="entry.plan_unit_minutes"
              />
              <label class="notifi-resetpass m-t-20" v-html="$t('plans.label_book_master')"></label>
            </div>
            <div class=" mt-2" style="font-weight: bold !important;color: black; text-align: right">
              {{ $t("plans.min") }}
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-2 col-lg-3 col-sm-2 col-form-label">{{ $t("plans.note") }}
            </label>
            <div class="col-xl-5 col-lg-6 col-sm-7">
              <app-text
                name="note"
                type="string1024"
                :class="['flex-fill']"
                v-model="entry.note"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-2 col-lg-3 col-sm-2 col-form-label">キャンセル期限
            </label>
            <label class="col-xl-1 col-lg-1 col-sm-1 col-form-label">予約日から
            </label>
            <div class="col-xl-1 col-lg-1 col-sm-1">
              <app-input
                name="limit_cancel_day"
                type="number-length:2"
                :class="['flex-fill']"
                v-model="entry.limit_cancel_day"
              />
            </div>
            <label class="col-xl-1 col-lg-1 col-sm-1 col-form-label">日前
            </label>
            <div class="col-xl-1 col-lg-1 col-sm-1">
              <app-input
                name="limit_cancel_hour"
                type="string:5"
                rules="hour_minute"
                :class="['flex-fill']"
                v-model="entry.limit_cancel_hour"
              />
            </div>
            <label class="col-xl-1 col-lg-1 col-sm-1 col-form-label">時前まで
            </label>
          </div>
          <div class="form-group row">
            <label class="col-xl-2 col-lg-3 col-sm-2 col-form-label">予約受付期限
            </label>
            <label class="col-xl-1col-lg-1 col-sm-1 col-form-label">予約日から
            </label>
            <div class="col-xl-1 col-lg-1 col-sm-1">
              <app-input
                name="limit_booking_day"
                type="number-length:2"
                :class="['flex-fill']"
                v-model="entry.limit_booking_day"
              />
            </div>
            <label class="col-xl-1 col-lg-1 col-sm-1 col-form-label">日前
            </label>
            <div class="col-xl-1 col-lg-1 col-sm-1">
              <app-input
                name="limit_booking_hour"
                type="string:5"
                rules="hour_minute"
                :class="['flex-fill']"
                v-model="entry.limit_booking_hour"
              />
            </div>
            <label class="col-xl-2 col-lg-3 col-sm-2 col-form-label">時前まで
            </label>
          </div>
          <div class="card-footer row justify-content-end gr-button">
            <button type="button" :disabled="!valid && validated"  class="btn btn-default m-r-10" @click="handlerCancelButton">
              {{ $t('common.cancel') }}
            </button>
            <button type="button"  class="btn btn btn-danger m-r-10"  :disabled="!valid && validated" @click="handlerDeletePlan" v-if="idEditing">
              {{ $t('apartments.delete') }}
            </button>
            <button  class="btn btn-primary" :disabled="!valid && validated" type="button" @click="createOrUpdate">
              {{idEditing ?  $t('common.save') :  $t('common.create')}}
            </button>
          </div>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>

  import {ROUTES} from "@constants";
  import {PERMISSION, RATE_TAX} from "@constants/api";

  export default {
    name: "PlanCreateOrEdit",

    data() {
      const id = this.$route.params.id
      return {
        meta: {
          plan_unit_minutes: [],
          facility : null,
          book_masters : [],
          unitTime : []
        },
        entry: {
          facility_id : this.$route.params.facility_id,
          status_enabled : 1,
          book_master_id : '',
          tax : "",
          is_reduced_tax : []
        },
        idEditing: id,
        loading: true,
        facility : null,
        startValidate : !!id,
        firstTimeLoad : false,
        rateTax: RATE_TAX
      }
    },
    async created() {
      await this.getMetaData()
      if (this.idEditing) {
        this.loading = false;
        await this.$request.get(this.ENDPOINT.FACILITY_PLAN_EDIT(this.idEditing)).then(res => {
          if (res.hasErrors()) {
            if (res.status === 'error'){
              this.__noticeError(this.$t(res.data.error_msg));
            }
            this.redirectDefaultValue()
          } else {
            this.entry = res.data.data
            this.loading = true;
          }
        })
      }

    },
    watch : {
      "entry.book_master_id": async function (value) {
          if (this.firstTimeLoad) {
              this.entry.plan_unit_minutes = ""
          }
          await this.changeBookMaster(value);
      }
    },
    mounted: async function () {
      $(".tooltip").tooltip("hide");
      let facilityID = this.$route.params.facility_id

      $(".content-header .breadcrumb a").attr( "href", `/admin/facility_plan/${facilityID}/list-page`)
    },
    methods :{
     async getMetaData() {
        await this.$request.get(this.ENDPOINT.UI_HELPER_PLANS(this.$route.params.facility_id)).then(res => {
          this.meta = {
            facility:  res.data.facility,
            unitTime: res.data.plan_unit_minutes,
            book_masters : res.data.book_masters,
            plan_unit_minutes : [
              {
                id: '',
                name: this.$t('common.choose'),
              }
            ]
          }
        })
      },
      closeModalTax() {
        this.$refs.plan_tax.hide();
      },
      focusOutEvent(){
        if ( this.entry.tax && this.entry.price&& ((this.entry.tax / this.entry.price) * 100) > RATE_TAX ) {
          this.$refs.plan_tax.show();
        }
      },
      changeBookMaster(value) {
       this.loading = false;
       var planUnitMinutes = [
          {
            id: '',
            name: this.$t('common.choose'),
          }
        ]
         if (value) {
            planUnitMinutes = this.meta.unitTime[this.entry.book_master_id];
         }
        this.meta = {
          facility:  this.meta.facility,
          unitTime: this.meta.unitTime,
          book_masters : this.meta.book_masters,
          plan_unit_minutes : planUnitMinutes
        }
        this.loading = true;
        this.firstTimeLoad = true;
      },
      async createOrUpdate() {
        if (!await this.$refs.entry.validate()) {
          return;
        }
        let res = null;
        let msg = "";

        if (this.idEditing) {
          res = await this.$request.patch(this.ENDPOINT.FACILITY_PLAN_EDIT(this.idEditing), this.entry);
          msg = this.$t("common.msg update ok")
        } else {
          res = await this.$request.post(this.ENDPOINT.FACILITY_PLAN_CREATE, this.entry);
          msg = this.$t("common.msg create ok")
        }
        if (res.hasErrors()) {
          this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
          if (res.status === 'error') {
            this.redirectDefaultValue()
          }
        } else {
          this.__noticeSuccess(msg);
          this.redirectDefaultValue();
        }
      },
      handlerDeletePlan() {
        this.$refs.plan_delete_modal.show();
      },
      async deleteInformation() {
        const res = await this.$request.delete(this.ENDPOINT.FACILITY_PLAN_DELETE(this.idEditing));
        if (!res.hasErrors()) {
          this.__noticeSuccess(this.$t("common.msg delete ok"));
          this.redirectDefaultValue();
        } else {
          this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
          this.$refs.plan_delete_modal.hide();
          if (res.status === 'error') {
            this.redirectDefaultValue()
          }
        }
      },
      handlerTax(){
        this.entry.tax = '';
      },
      handlerCancelButton() {
        if(this.$store.state.setting.prevRouter.name === ROUTES.ADMIN.FACILITY_PLAN_LIST) {
          this.__redirectBack()
        } else {
          this.redirectDefaultValue()
        }
      },
      handlerApartmentNameClick(apartment_id) {
        let routeApartment = this.$router.resolve({name: this.ROUTES.ADMIN.APARTMENT_EDIT, params: {id: apartment_id}});
        window.open(routeApartment.href, '_blank');
      },
      handlerFacilityNameClick(facility_id) {
        let routeFacility = this.$router.resolve({name: this.ROUTES.ADMIN.FACILITY_EDIT, params: {id: facility_id}});
        window.open(routeFacility.href, '_blank');
      },
      redirectDefaultValue() {
          this.$router.push({name: this.ROUTES.ADMIN.FACILITY_PLAN_LIST, params: {facility_id: this.$route.params.facility_id}})
      },
      copy() {
        var copyText = document.getElementById("plan_url");
        copyText.select();
        copyText.setSelectionRange(0, 99999); /* For mobile devices */
        navigator.clipboard.writeText(copyText.value);
        alert("Copied url plan: " + copyText.value);
      }
    }
  }
</script>

<style scoped>
.apartment_name:hover {
  cursor: pointer;
  color: #0056b3 !important;
}
  @media screen and (min-width: 1199px) {
    .select_box {
      flex: 0 0 37%;
      max-width: 37%;
      margin-right: 20px !important;
    }
  }
</style>
